<template>
  <b-modal
    :id="`modal-train-customer-service-fee-${id}`"
    title="Sử dụng profile dịch vụ"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    size="lg"
    no-close-on-backdrop
    hide-footer
    no-close-on-esc
    @show="openModalHandle"
  >
    <div
      v-if="!employeeProfileList"
      class="py-2"
    >
      <IAmOverlay
        :loading="!employeeProfileList"
        :spinner-variant="'info'"
      />
    </div>

    <app-collapse v-else>
      <!-- accordion -->
      <app-collapse-item
        v-for="(employeeProfileItem, employeeProfileIndex) in employeeProfileList"
        :key="employeeProfileIndex"
        title
        :is-visible="(selectedProfile && (selectedProfile.id === employeeProfileItem.id)) || (!selectedProfile && employeeProfileItem.isDefault)"
        :class="`rounded-lg shadow mb-75
        ${(selectedProfile && (selectedProfile.id === employeeProfileItem.id)) || (!selectedProfile && employeeProfileItem.isDefault) ? 'border-success-2' : 'border' }
        `"
      >
        <template #header>
          <div class="d-flex-between w-100 mr-2">
            <span
              :class="`fw-700
              ${isMobileView ? 'text-heading-5' : 'text-heading-3'}
              `"
            >
              {{ employeeProfileItem.name }}
              <BBadge
                v-if="employeeProfileItem.isDefault"
                variant="warning"
                class="badge-glow round pills ml-50 px-50 px-lg-75"
              >
                {{ $t('fee.default') }}
              </BBadge>
            </span>

            <BBadge
              v-if="selectedProfile ? selectedProfile.id === employeeProfileItem.id : employeeProfileItem.isDefault"
              variant="success"
              class="badge-glow round pills ml-50 py-50 px-1"
            >
              {{ $t('using') }}
            </BBadge>

            <b-button
              v-else
              variant="outline-danger"
              class="rounded py-50"
              @click.prevent.stop="handleApplyProfile(employeeProfileItem)"
            >
              {{ $t('apply') }}
            </b-button>
          </div>
        </template>

        <div>
          <BTable
            :fields="tableColumns"
            striped
            bordered
            hover
            responsive
            :empty-text="$t('noMatchingResult')"
            :items="employeeProfileItem.serviceFeeConfigs"
          >
            <template
              v-for="column in tableColumns"
              #[`head(${column.key})`]="data"
            >
              <div
                :key="column.label"
                class="text-dark text-nowrap"
                :class="{
                  'text-right': ['adultAmount', 'childAmount', 'infantAmount'].includes(data.label),
                  'text-center': ['action'].includes(data.label)
                }"
              >
                {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
              </div>
            </template>
            <template #cell(feeType)="data">
              <div>
                <span class="fw-700">{{ $t(`fee.${data.item.ticketType}`) }}</span>
              </div>
              {{ $t(`fee.${data.item.feeType}`) }}
            </template>
            <!-- <template #cell(ticketType)="data" /> -->
            <template #cell(adultAmount)="data">
              <div class="text-right fw-700">
                {{ formatCurrency(data.item.adultAmount) }}
              </div>
            </template>
            <template #cell(childAmount)="data">
              <div class="text-right fw-700">
                {{ formatCurrency(data.item.childAmount) }}
              </div>
            </template>
          </BTable>
        </div>
      </app-collapse-item>

      <div v-if="employeeProfileList && !employeeProfileList.length">
        <b-alert
          show
          variant="warning"
          class="py-50 my-50 pl-1"
        >
          <span class="fw-700">Chưa có cấu hình phí dịch vụ</span>
        </b-alert>
      </div>
    </app-collapse>

    <div class="d-flex-center mt-2">
      <b-button
        variant="primary"
        class="d-flex-center px-1"
        @click="handleGoToServiceFeeConfig"
      >
        <feather-icon
          icon="PlusIcon"
          size="20"
          class="mr-25"
        />
        Thêm mới phí cấu hình
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton, BTable, BBadge, BAlert,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'

import { formatCurrency } from '@/@core/utils/filter'
import store from '@/store'
import { apiEmployee } from '@/api'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BTable,
    BBadge,
    BAlert,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    AppCollapse: () => import('@/@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@/@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { toastSuccess } = useToast()
    const employeeId = computed(() => store.getters['userStore/getMeDataId'])
    const employeeProfileList = ref()

    async function openModalHandle() {
      if (!employeeProfileList.value) {
        const { data: res } = await apiEmployee.getEmployeeProfile(employeeId.value)
        employeeProfileList.value = res.map(item => ({
          ...item,
          serviceFeeConfigs: item.serviceFeeConfigs.filter(fee => ['TRAIN'].includes(fee.airline)),
        }))
      }
    }

    const tableColumns = ref([
      { label: 'airline', key: 'airline' },
      { label: 'feeType', key: 'feeType' },
      { label: 'adultAmount', key: 'adultAmount' },
      { label: 'childAmount', key: 'childAmount' },
    ])

    const selectedProfile = computed(() => store.state['app-train'].selectedProfileServiceFee) // (object) fee

    function handleApplyProfile(employeeProfile) {
      this.$bvModal.show('modal-api-loading')
      const timeoutId = setTimeout(() => {
        store.dispatch('app-train/setSelectedProfileServiceFee', employeeProfile)
        toastSuccess('flight.applyProfileServiceFeeSuccessful')
        clearTimeout(timeoutId)
        this.$bvModal.hide(`modal-train-customer-service-fee-${props.id}`)
        this.$bvModal.hide('modal-api-loading')
      }, 100)
    }

    function handleGoToServiceFeeConfig() {
      this.$router.push({ name: 'apps-configsServiceFee' })
    }

    return {
      openModalHandle,
      employeeProfileList,
      tableColumns,
      formatCurrency,
      handleApplyProfile,
      selectedProfile,
      handleGoToServiceFeeConfig,
    }
  },
}
</script>
